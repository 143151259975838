<script>
import appConfig from "@/app.config";
import axios from "axios";
import VueHtml2pdf from "vue-html2pdf";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Tur Listesi ",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      loading: true,
      item: [],
    };
  },
  created() {
    this.getData();
  },
  components: {
    VueHtml2pdf,
  },
  methods: {
    getData() {
      const id = this.$route.params.id;
      const api_url = process.env.VUE_APP_BASEURL + "/reservation/" + id;

      axios
        .get(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.item = response.data?.data;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>

<template>
  <div>
    <b-button
      variant="outline-secondary"
      class="position-absolute"
      style="
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1000000;
      "
      @click="generateReport"
    >
      <i class="fa fa-print"></i> PDF Oluştur
    </b-button>
    <vue-html2pdf
      ref="html2Pdf"
      :enable-download="false"
      :filename="`voucher-${item.id}`"
      :show-layout="true"
      :float-layout="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
    >
      <section slot="pdf-content">
        <aside id="body" class="container-fluid position-relative">
          <aside class="row mb-3">
            <aside class="col-12 text-center">
              <img
                src="@/assets/images/logo-fluid.png"
                alt="Reem Travel"
                height="100"
              />
            </aside>
          </aside>
          <aside class="row mt-4">
            <div class="col-lg-4">
              <b-card header-class="bg-transparent">
                <h5 class="card-title mt-0">Customer Information</h5>
                <p class="card-text">
                  <b>Name: </b> {{ item.customer.customer_name }}<br />
                  <b>Reservation No: </b> #{{ item.id }}<br />
                  <b>Date: </b>
                  {{ item.created_at | moment("DD.MM.YYYY HH:mm") }}
                </p>
              </b-card>
            </div>
            <div class="col-lg-4">
              <b-card header-class="bg-transparent">
                <h5 class="card-title mt-0">Agency Information</h5>
                <p class="card-text">
                  <b>Name: </b> {{ item.agency.company_name }}<br />
                </p>
              </b-card>
            </div>
          </aside>
          <aside class="row">
            <div class="col-lg-12">
              <div class="card">
                <div class="table-responsive mb-0">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>City</th>
                        <th>Tour Type</th>
                        <th>Car Plate</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="reservation in item.tourRoutes"
                        :key="reservation.id"
                      >
                        <td>{{ reservation.date | moment("DD.MM.YYYY") }}</td>
                        <td>{{ reservation.city.city }}</td>
                        <td>{{ reservation.tour_type.label }}</td>
                        <td>{{ reservation.vehicle.plate }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </aside>
        </aside>
      </section>
    </vue-html2pdf>
  </div>
</template>

<style scoped lang="scss">
#body,
body {
  background-color: #fff !important;
}
.card {
  background-color: #f1f5f7 !important;
}
thead {
  background-color: #acdfda;
}
tbody {
  &:nth-child(2n) {
    background-color: rgba(172, 223, 218, 0.2);
  }
}
.table-reem {
  background-color: #acdfda;
}

.card-header {
  background: #acdfda;
}
</style>

<style>
.vue-html2pdf .layout-container {
  background: none !important;
}
body {
  background-color: #fff !important;
}
</style>
